import { Route, Routes, Navigate } from "react-router-dom";
import MasterLayout from "../layout/MasterLayout";
import { DashboardWrapper } from "../app/pages/dashboard/DashboardWrapper";
import React, { Suspense } from "react";
import SpinnerLoader from "../components/loaders/SpinnerLoader";

const PrivateRoutes = () => {
  const CategoryWrapper = React.lazy(() => import("../app/pages/category/CategoryWrapper"));
  const SegmentWrapper = React.lazy(() => import("../app/pages/segments/SegmentWrapper"));
  const SegmentStatistics = React.lazy(() => import("../app/pages/segments/SegmentStatistics"));
  const CategoryStatistics = React.lazy(() => import("../components/category/CategoryStatistics"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path={"auth/*"} element={<Navigate to={"/dashboard"} />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='category' element={
          <Suspense fallback={<SpinnerLoader />}>
            <CategoryWrapper />
          </Suspense>
        } />
        <Route path='segments' element={
          <Suspense fallback={<SpinnerLoader />}>
            <SegmentWrapper />
          </Suspense>
        } />
        <Route path='segments/stats/*' element={
          <Suspense fallback={<SpinnerLoader />}>
            <SegmentStatistics />
          </Suspense>
        } />
        <Route path='category/stats/*' element={
          <Suspense fallback={<SpinnerLoader />}>
            <CategoryStatistics />
          </Suspense>
        } />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};


export { PrivateRoutes };
