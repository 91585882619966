import React from 'react'
import { DashboardChart } from './DashboardChart'
import numeral from 'numeral';
import { Link } from 'react-router-dom';

type Props = {
    cardTitle: string
    chartName: string;
    analyticsData: any
    loading: boolean
    error: string
    getAllSegmentData: () => void
};
const PageViewChartCard = ({ getAllSegmentData, cardTitle, chartName, analyticsData, loading, error }: Props) => {

    return (
        <div className="card card-flush h-xl-100">
            {/* <!--begin::Header--> */}
            <div className="card-header py-7">
                {/* <!--begin::Statistics--> */}
                {!loading ? <div className="m-0">
                    {/* <!--begin::Heading--> */}
                    <div className="d-flex align-items-center mb-2">
                        {/* <!--begin::Title--> */}
                        <span className="fs-2hx fw-bold text-danger me-2 lh-1 ls-n2">{numeral(analyticsData?.pageViews).format('0.00 a').toUpperCase()}</span>
                        {/* <!--end::Title--> */}
                    </div>
                    {/* <!--end::Heading--> */}
                    {/* <!--begin::Description--> */}
                    <span className="fs-6 fw-semibold text-gray-500">{cardTitle}</span>
                    {/* <!--end::Description--> */}

                </div> :
                    <div className="m-0 placeholder-glow">
                        <div className="d-flex align-items-center mb-2">
                            <span className="fs-2hx fw-bold text-danger me-2 lh-1 ls-n2 placeholder"></span>
                        </div>
                        <span className="fs-6 fw-semibold text-gray-500 placeholder"></span>
                    </div>}
                <div className="card-toolbar dropdown-center">
                    <button className="btn fs-5 fw-bold py-2 px-4 rounded-2 dropdown-toggle" style={{ color: "white", backgroundColor: "rgb(163 163 163)" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Last 7 days
                    </button>
                    <ul className='dropdown-menu'>
                        <li className='dropdown-item cursor-pointer text-dark'>Last 7 days</li>
                        <li><Link className='dropdown-item cursor-pointer text-dark' to={"/segments/stats/-1"}>View More</Link></li>
                    </ul>
                </div>
                {/* <!--end::Statistics--> */}
            </div>
            {/* <!--end::Header--> */}
            {/* <!--begin::Body--> */}
            <div className="card-body d-flex align-items-end ps-4 pe-0 pb-4">
                {/* <!--begin::Chart--> */}
                {loading || error ?
                    (<div className="d-flex justify-content-center align-items-center w-100 position-relative" style={{ height: "350px" }}>
                        <div className="position-relative d-flex justify-content-center align-items-center" style={{ width: "300px", height: "200px" }}>
                            <img className="w-100 h-100 position-absolute" src="/media/chartskeleton/barChartSkeleton.png" alt="" />
                            <div className="position-absolute d-flex flex-column align-items-center">
                                <div className='fs-2 text-black fw-bold'>{error || "Loading data..."}</div>
                                {error? <button className='btn btn-primary shadow-sm py-2' onClick={getAllSegmentData}>Reload</button>:<></>}
                            </div>

                        </div>


                    </div>)
                    :
                    <DashboardChart
                        chartName={chartName}
                        axisData={analyticsData?.pageViewChartData}
                        className="w-100 mb-5 mb-xl-8"
                        color="--bs-danger-active"
                    />}
                {/* <!--end::Chart--> */}
            </div>
            {/* <!--end::Body--> */}
        </div>
    )
}

export default PageViewChartCard