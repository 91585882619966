import { UserModel } from "./core/_models";

export const USER_NAME = process.env.REACT_APP_USER_NAME;
export const USER_PASSWORD = process.env.REACT_APP_USER_PASSWORD;

const user:UserModel = {
  "id": 2,
  "username":"admin@Demo",
  "password":undefined,
  "first_name": "Alvena",
  "last_name": "Ward",
  "email": "admin@demo.com",
  "auth":{
    "api_token": "$2y$10$qyWRyuvGf4t9hAOndcV.vu.9ro6LFObwA5ovBoUtmB2ja4i9ipKAW"
  }
}
// Server should return AuthModel
export function login(email: string, password: string) {
  if(email===USER_NAME && password===USER_PASSWORD){
    return user
  }
  throw new Error("Incorrect credetials")
}

export function getUserByToken(token: string) {
  if(user.auth?.api_token===token){
    return user
  }
  throw new Error("Incorrect credetials")
}
