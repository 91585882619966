import { FC } from 'react'

import { PageTitle } from '../../../layout/core'
import { ToolbarWrapper } from '../../../components/toolbar'
import { Content } from '../../../components/content'
import DashboardChartsWrapper from './components/DashboardChartsWrapper'
import DailyReport from './components/DailyReport'

const DashboardPage: FC = () => (
  <>
    <ToolbarWrapper />
    <Content>
      <div className='row'>
        <div className='row g-0 px-2 col-12 col-xl-9'>
          <DashboardChartsWrapper />
        </div>
      </div>
      <div className='col-12 col-xl-9 mt-10 py-5'>

        <div className='row g-0 px-2'>
          <div className='py-2'>
            <DailyReport className='mb-5 mb-xl-8'/>
            
          </div>
        </div>
      </div>
    </Content>
  </>
)

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Home - Dashboard</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
