

import DynamicTable from '../../GlobalComponents/DynamicTable';
interface IPropTypes {
    state: any,
    fetchDailyReport: () => void,
}
const DailyReportTable = ({ state }: IPropTypes) => {


    return (
        <div className='table-responsive'>
             <DynamicTable
                data={state.reportData}
                colDefs={[
                    { headerName: "Users", dataKey: "users" },
                    { headerName: "Views", dataKey: "views" },
                    { headerName: "Stories", dataKey: "stories" },
                ]}
                defaultColDef={{ headerName: "Category", dataKey: "parentcategory" }}
                loading={state.loading}
            />
        </div>
    )
}

export default DailyReportTable