import {useEffect, useState} from 'react'
import {Tab} from "bootstrap"
import {
  MenuComponent,
  DrawerComponent,
  ToggleComponent,
  SwapperComponent,
} from '../assets/ts/components'

import {useLayout} from './core'

export function MasterInit() {
  const {config} = useLayout()
  const [initialized, setInitialized] = useState(false)
  const pluginsInitialization = () => {
    setTimeout(() => {
      ToggleComponent.bootstrap()
      DrawerComponent.bootstrap()
      MenuComponent.bootstrap()
      SwapperComponent.bootstrap()
      document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
        Tab.getOrCreateInstance(tab)
      })
    }, 500)
  }

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      pluginsInitialization()
    }
  }, [config, initialized])

  return <></>
}
