import React, { useEffect, useReducer, useState } from 'react'
import UserChartCard from './UserChartCard'
import PageViewChartCard from './PageViewChartCard'
import axios from 'axios';
import { StringMap, EventAction, EventsState } from '../utils/DashboardTypes';

const initialState: EventsState = {
    loading: true,
    analyticsData: {
        uniqueUsers: 0, pageViews: 0, userChartData: {
            xAxis: [],
            yAxis: [],
        },
        pageViewChartData: {
            xAxis: [],
            yAxis: [],
        }
    },
    error: null
}
const reducer = (state: EventsState, action: EventAction) => {
    switch (action.type) {
        case 'FETCH_BEGIN':
            return { ...state, loading: true, error: null }
        case 'FETCH_SUCCESS':
            return { ...state, loading: false, analyticsData: action.payload }
        case 'FETCH_FAIL':
            return { ...state, loading: false, error: action.error }
    }
}
let months: StringMap = { "01": "Jan", "02": "Feb", "03": "Mar", "04": "Apr", "05": "May", "06": "Jun", "07": "July", "08": "Aug", "09": "Sept", "10": "Oct", "11": "Nov", "12": "Dec" }

const DashboardChartsWrapper = () => {

    const [state, dispatch] = useReducer(reducer, initialState)
    const getAllSegmentData = async () => {
        dispatch({ type: "FETCH_BEGIN" })
        try {
            const data = await handleMinuteSegment("-1", "7d")
            if (data)
                dispatch({ type: "FETCH_SUCCESS", payload: data })
            else dispatch({ type: "FETCH_FAIL", error: "Some error occured" })
        } catch (error) {
            dispatch({ type: "FETCH_FAIL", error: "Some error occured" })
        }
    }
    useEffect(() => {
        getAllSegmentData()
    }, []);
    return (
        <>
            <div className="col-12 col-lg-6 py-2 py-lg-0 px-lg-2">
                <UserChartCard
                    cardTitle='Unique users (All segments)'
                    chartName='Users'
                    analyticsData={state.analyticsData}
                    loading={state.loading}
                    error={state.error}
                    getAllSegmentData={getAllSegmentData}
                />
            </div>
            <div className="col-12 col-lg-6 py-2 py-lg-0 px-lg-2">
                <PageViewChartCard
                    cardTitle='Page views (All segments)'
                    chartName={"Page views"}
                    analyticsData={state.analyticsData}
                    loading={state.loading}
                    error={state.error}
                    getAllSegmentData={getAllSegmentData}
                />
            </div>
        </>
    )
}

export default DashboardChartsWrapper

const handleMinuteSegment = async (segment?: string, chartType?: string) => {
    try {
        if (segment) {
            const response = await axios.get(`/segments/segmentData`, {
                params: {
                    segment: segment,
                    chartType: chartType,
                },
            });
            let lengthXAxis =
                response.data.keysXAxis && response.data.keysXAxis.length;
            let eventsData = response.data.eventsData
            let uniqueUser = response.data.totalCount
            let xaxisData = response.data.keysXAxis;
            let pageYAxisDate = []
            let totalPageViews = 0
            if (response.data.keysXAxis[0] === "All")
                xaxisData = response.data.keysXAxis.slice(1, lengthXAxis);

            for (let i = 0; i < xaxisData.length; i++) {
                totalPageViews += eventsData[xaxisData[i]]?.PAGE_VIEW || 0
                pageYAxisDate[i] = eventsData[xaxisData[i]]?.PAGE_VIEW || 0
                if (xaxisData[i].includes("DAY")) {
                    let date: string = xaxisData[i].split(":")[1];

                    xaxisData[i] = date.split("-")[2] + " " + months[date.split("-")[1]];
                }
                else xaxisData[i] = xaxisData[i].split(" ")[1];
            }
            let userYaxisData = response.data.valueYAxis;
            if (response.data.keysXAxis[0] === "All")
                userYaxisData = response.data.valueYAxis.slice(1, lengthXAxis);
            return { uniqueUsers: uniqueUser, pageViews: totalPageViews, userChartData: { xAxis: xaxisData, yAxis: userYaxisData }, pageViewChartData: { xAxis: xaxisData, yAxis: pageYAxisDate } }
        }
    } catch (err) {
        console.log(err);
    }
};