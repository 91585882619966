import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import { LayoutProvider } from '../layout/core';
import { AuthInit, AuthPage, useAuth } from '../app/modules/auth';
import { PrivateRoutes } from './PrivateRoutes';
import { MasterInit } from '../layout/MasterInit';
import { ErrorsPage } from '../components/errors/ErrorsPage';
import SpinnerLoader from '../components/loaders/SpinnerLoader';

function App() {
  return (
    <Suspense fallback={<SpinnerLoader />}>
      <LayoutProvider>
        <AuthInit>
          <Outlet />
          <MasterInit />
        </AuthInit>
      </LayoutProvider>
    </Suspense>
  )
}

function AppRoutes() {
  const { currentUser } = useAuth();

  return (
    <Routes>
      <Route element={<App />}>
      <Route path='error/*' element={<ErrorsPage />} />
        {currentUser ? (
          <>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={<Navigate to='/dashboard' />} />
          </>
        ) : (
          <>
            <Route path='auth/*' element={<AuthPage />} />
            <Route path='*' element={<Navigate to='/auth' />} />
          </>
        )}
      </Route>
    </Routes>
  )
}

export default AppRoutes;
