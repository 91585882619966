import React, { FC, useMemo } from 'react'
import { BsCaretRightFill, BsCaretLeftFill } from "react-icons/bs";

import "./Pagination.css"
type PaginationProps = {
  page: number,
  totalData: number,
  handlePageChange: (page: number) => void
}
const Pagination: FC<PaginationProps> = ({ page, totalData, handlePageChange }) => {

  const pageNumClass = "fw-bold d-flex justify-content-center align-items-center rounded-circle"
  const lastPage = useMemo(() => {
    return Math.ceil(totalData / 10)
  }, [totalData])
  const numbers = Array.from({ length: lastPage }, (_, index) => index + 1);
  if (lastPage < 7) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ userSelect: "none" }}>
        <div onClick={() => handlePageChange(page > 1 ? page - 1 : page)} className={`${pageNumClass} pageNumber rounded-circle arrowBtn`}>
          <BsCaretLeftFill className={`${page === 1 ? "text-muted" : ""}`} />
        </div>
        {numbers.map((item) => {
          return <div onClick={() => handlePageChange(item)} className={`${pageNumClass} pageNumber ${page === item ? "pageNumber-active" : page - 1 === item || page + 1 === item ? "neighbourPageNumber" : "otherPageNumber"}`}>{item}</div>
        })}
        <div onClick={() => handlePageChange(page < lastPage ? page + 1 : page)} className={`${pageNumClass} pageNumber rounded-circle arrowBtn`}>
          <BsCaretRightFill className={`${page === lastPage ? "text-muted" : ""}`} />
        </div>
      </div>)
  }
  return (
    <div className='d-flex justify-content-center align-items-center' style={{ userSelect: "none" }}>

      <div onClick={() => handlePageChange(page > 1 ? page - 1 : page)} className={`${pageNumClass} pageNumber rounded-circle arrowBtn`}>
        <BsCaretLeftFill className='' />
      </div>

      {page >= 3 && <div onClick={() => handlePageChange(1)} className={`${pageNumClass} pageNumber fs-4`} style={{ width: "32px", height: "32px" }}>{1}</div>}

      {page > 3 && <div className={`${pageNumClass} extraDots`}>...</div>}

      {page > 1 && <div onClick={() => handlePageChange(page - 1)} className={`${pageNumClass} pageNumber fs-3`} style={{ width: "35px", height: "35px" }}>{page - 1}</div>}
      <div className={`${pageNumClass} pageNumber pageNumber-active fs-2`} style={{ width: "40px", height: "40px" }}>{page}</div>
      {page < lastPage && <div onClick={() => handlePageChange(page + 1)} className={`${pageNumClass} pageNumber fs-3`} style={{ width: "35px", height: "35px" }}>{page + 1}</div>}

      {page < lastPage - 2 && <div className={`${pageNumClass} extraDots`}>...</div>}

      {page <= lastPage - 2 && <div onClick={() => handlePageChange(lastPage)} className={`${pageNumClass} pageNumber fs-4`} style={{ width: "32px", height: "32px" }}>{lastPage}</div>}

      <div onClick={() => handlePageChange(page < lastPage ? page + 1 : page)} className={`${pageNumClass} pageNumber rounded-circle arrowBtn`}>
        <BsCaretRightFill className='' />
      </div>
    </div>
  )
}

export default Pagination