import React, { useEffect, useRef, FC, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS, getCSSVariableValue } from "../../../../assets/ts/_utils";

type Props = {
  chartName: string;
  className: string;
  axisData: any;
  color: string;
};

const DashboardChart: FC<Props> = ({ className, axisData, chartName, color }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const NumberWithCommas = (number :string|number|number[]) => {
    const formattedNumber = new Intl.NumberFormat('en-IN').format(Number(number));
    return formattedNumber;
};
  function getChartOptions(height: number, axisData: any): ApexOptions {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue(color);
    const secondaryColor = getCSSVariableValue("--bs-gray-300");

    return {
      series: [
        {
          name: chartName,
          data: axisData.yAxis,
        },
      ],
      chart: {
        fontFamily: "inherit",
        type: "bar",
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          borderRadius: 3,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
        formatter:(val)=>NumberWithCommas(val)
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: axisData.xAxis,
        title: {
          text: "Date",
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          rotate: -90,
          hideOverlappingLabels: true,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
          formatter:(val)=>NumberWithCommas(val)
        },
        title: {
          text: chartName,
        },
      },
      fill: {
        opacity: 1,
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return "" + NumberWithCommas(val) + "";
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };
  }
  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, axisData)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, axisData]);

  return (
    <div className={`${className}`}>
        <div
          ref={chartRef}
          id="kt_charts_widget_2_chart"
          style={{ height: "350px" }}
        ></div>
      {/* end::Chart */}
    </div>
  );
};

export { DashboardChart };



