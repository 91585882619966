import React,{ FC, useState } from 'react'
import { KTIcon } from '../../../helpers/components/KTIcon'
import { usePageData } from '../../../layout/core'
type options = {
  label: string,
  value: string
}
type IProps = {
  onSearch: (search?: string, options?: string) => void
  clearFilter: () => void
  bgColor: string
}

const Searchbar: FC<IProps> = ({ onSearch, bgColor, clearFilter }) => {
  const { pageTitle } = usePageData()
  const [search, setSearch] = useState('')
  const [searchOption, setSearchOption] = useState('')
  const [error, setError] = useState('')
  const clearSearch = () => {
    setSearch('');
    setSearchOption('');
    clearFilter()
    setError('')
  }
  React.useEffect(()=>{
    if(search.length===0){
      clearSearch()
    }
  },[search])
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (search.length > 2) {
        setError('')
        onSearch(search, searchOption)
      }
      else setError('Enter atleast 3 characters')
    }
  }
  return (
    <div
      className='card rounded-0 shadow-none border-0 bgi-no-repeat bgi-position-x-end bgi-size-cover'
      style={{
        backgroundColor: bgColor,
        backgroundSize: 'auto 100%',
      }}
    >
      {/* begin::body */}
      <div className='card-body container-xxl pt-10 pb-8'>
        {/*begin::Title */}
        <div className=' d-flex align-items-center'>
          <h1 className='fw-bold me-3 text-white'>Search {pageTitle}</h1>

          <span className='fw-bold text-white opacity-50'>{pageTitle} Management System</span>
        </div>
        {/* end::Title */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-column'>
          {/*begin::Block*/}
          <div className='row d-md-flex align-md-items-center'>
            {/* begin::Simple form */}
            <div className={`col-lg-11 rounded d-flex flex-column flex-md-row align-items-md-center bg-body p-5 w-xxl-450px h-md-60px mt-5`}>
              {/* begin::Row */}
              <div className='row flex-grow-1 mb-5 mb-md-0 position-relative'>
                {/* begin::Col */}
                <div className={`col-md-12 d-flex align-items-center mb-3 mb-md-0`}>
                  <KTIcon iconName='magnifier' className='fs-1 text-gray-500 me-1' />
                  {/* begin::Input */}
                  <input
                    type='text'
                    className='form-control form-control-flush flex-grow-1'
                    name='search'
                    value={search}
                    onChange={(e) =>{ setSearch(e.target.value);if(error)setError('')}}
                    placeholder={`${pageTitle} label`}
                    onKeyDown={handleKeyPress}
                  />

                  {/* end::Input */}
                </div>
                <div className='fw-medium fs-5' style={{ color: "red",position:"absolute",bottom:-10,left:30 }}>
                  {error}
                </div>
                {/* end::Col */}

                {/* begin::Col */}

                {/* end::Col */}
              </div>
              {/* end::Row */}

              {/* begin::Action */}
              <div className='min-w-150px text-end'>
                <button type='submit' onClick={() => {
                  if (search.length > 2) {
                    setError('')
                    onSearch(search, searchOption)
                  }
                  else setError('Enter atleast 3 characters')
                }
                } className='col-12 btn btn-light' id='kt_advanced_search_button_1'>
                  Search
                </button>
              </div>
              {/* end::Action */}
            </div>
            {(search) && <div className='col-1 d-flex align-items-center'>
              <button type="button" onClick={clearSearch} className="btn btn-secondary d-flex py-6 mt-5">Clear</button>
            </div>}
            {/* end::Simple form */}

          </div>

          {/*end::Block*/}
        </div>
        {/*end::Wrapper*/}
      </div>
      {/*end::body*/}
    </div>
  )
}
export { Searchbar }
