import React from 'react'
import { KTIcon } from '../../../helpers';
import Pagination from './Pagination/Pagination';
interface DataItem {
    [key: string]: string | number;
}

interface SortConfig {
    key: string;
    direction: 'ascending' | 'descending';
}
type ColHeaderType = {
    headerName: string,
    minWidth?: string,
    dataKey: string
}
interface SortableTableProps {
    data: Array<DataItem>;
    colDefs: Array<ColHeaderType>;
    defaultColDef: ColHeaderType;
    loading: boolean;
}

const DynamicTable: React.FC<SortableTableProps> = ({ data, colDefs, defaultColDef, loading }) => {
    const [sortConfig, setSortConfig] = React.useState<SortConfig | null>({ key: colDefs[0].dataKey, direction: "descending" });
    const [currentPage, setCurrentPage] = React.useState(1);

    const sortedData = React.useMemo(() => {
        let sortableData = [...data];
        if (sortConfig !== null) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const requestSort = (key: string) => {
        let direction: 'ascending' | 'descending' = 'descending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'descending'
        ) {
            direction = 'ascending';
        }
        setSortConfig({ key, direction });
    };

    const getClassNamesFor = (key: string) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === key ? sortConfig.direction : undefined;
    };
    const getSortIcon = (key: string) => {
        if (!sortConfig || sortConfig.key !== key) {
            return 'arrow-up-down';
        }
        return sortConfig.direction === 'ascending' ? (
            "arrow-up"
        ) : (
            "arrow-down"
        );
    };
    return (
        <div>
            <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-200 align-middle gs-0 gy-4'>
                    <thead>
                        <tr className='fw-bold text-black bg-light'>
                            <th className='ps-4 min-w-200px min-w-md-300px rounded-start' style={{ minWidth: defaultColDef.minWidth }}>{defaultColDef.headerName}</th>
                            {colDefs.map((column) => (
                                <th
                                    key={column.headerName}
                                    onClick={() => requestSort(column.dataKey)}
                                    className='min-w-80px min-w-md-105px'
                                >
                                    <div className='d-flex justify-content-between cursor-pointer'>{column.headerName} <KTIcon iconName={getSortIcon(column.dataKey)} className='fs-1 text-black me-1' /></div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    {loading ?
                        <tbody className=''>
                            <RowPlaceholder />
                            <RowPlaceholder />
                            <RowPlaceholder />
                            <RowPlaceholder />
                            <RowPlaceholder />
                            <RowPlaceholder />
                        </tbody>
                        :
                        <tbody>
                            {sortedData.slice((currentPage-1)*10,((currentPage-1)*10)+10).map((item, index) => (
                                <tr key={index}>
                                    <td ><div className='text-gray-800 text-uppercase ps-4 fw-bold d-block mb-1 fs-6'>{item[defaultColDef.dataKey]}</div></td>
                                    {colDefs.map((column) => (
                                        <td key={column.headerName}><div className='text-danger fw-bold d-block mb-1 fs-6'>{NumberWithCommas(item[column.dataKey])}</div></td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>}
                </table>
            </div>
            <div className='mt-1 mt-md-3 px-md-4 d-flex justify-content-end' style={{ height: "70px", borderTop: "1px solid #cccccc" }}>
                <Pagination page={currentPage} totalData={sortedData.length} handlePageChange={(page: number) => setCurrentPage(page)} />
            </div>
        </div>
    );
}

export default DynamicTable

const NumberWithCommas = (number :string|number) => {
    const formattedNumber = new Intl.NumberFormat('en-IN').format(Number(number));
    return formattedNumber;
};


const RowPlaceholder = () => {
    return (
        <tr>
            <td className='placeholder-glow'>
                <div className='ps-4 d-flex align-items-center placeholder h-50px rounded-start'>
                    <div className='d-flex justify-content-start flex-column'>
                    </div>
                </div>
            </td>
            <td className='placeholder-glow'>
                <div className='d-block mb-1 fs-6 placeholder h-50px'>
                </div>
            </td>
            <td className='placeholder-glow'>
                <div className='d-block mb-1 fs-6 placeholder h-50px'>
                </div>
            </td>
            <td className='placeholder-glow'>
                <div className='d-block mb-1 fs-6 placeholder h-50px rounded-end'>
                </div>
            </td>
        </tr>
    )
}