import { DailyReportState, EventAction } from '../utils/DashboardTypes'
const dummyData = [{"parentcategory": "city-and-states", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "india-news", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "entertainment", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "kavya", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "spirituality", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "lifestyle", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "cricket", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "humour", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "utility", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "sports", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "education", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "automobiles", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "business", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "technology", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "bizarre-news", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "world", "stories": 0, "users": 0, "views": 0},
    {"parentcategory": "fashion", "stories": 0, "users": 0, "views": 0}]
export const DailyReportreducer = (state: DailyReportState, action: EventAction) => {
    switch (action.type) {
        case 'FETCH_BEGIN':
            return { ...state, loading: true, error:null }
        case 'FETCH_SUCCESS':
            return { ...state, loading: false, reportData: action.payload.length>0?action.payload:dummyData,chart: action.payload.length>0 }
        case 'FETCH_FAIL':
            return { ...state, loading: false, error: action.error }
    }
}