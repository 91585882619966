import axios from "axios";
import { CategoryTypes } from "./_model";

const getAllCategories = async () => {
    try {
        const response = await axios.get(`categories/getCategories`);
        if (response.data && response.data.length) {
            return response.data
        }
        else {
            return ({ error: "error 404", message: "Data not found" })
        }
    } catch (error) {
        console.log(error);
        return ({ error: "error 500", message: "Internal server error" });
    }
}
const saveCategory = async (values: any) => {
    try {
        const res = await axios.post(`categories/addCategory`, values)
        const data = await res.data
        
        return data;
    } catch (error:any) {
        return ({ error: error.response.status, message: error.response.data.message });
    }
}
const filterByCategoryType = (data: Array<CategoryTypes>) => {
    let plist = data.filter((d) => { return d.type === "PLATFORM" });
    let alist = data.filter((d) => { return d.type === "AGE_GROUP" });
    let clist = data.filter((d) => { return d.type === "CONTENT_CATEGORY" });
    let llist = data.filter((d) => { return d.type === "LOCATION" });
    return ({ total: data, platform: plist, ageGroup: alist, contentCategory: clist, location: llist });
}
export { getAllCategories, filterByCategoryType, saveCategory }