import React from 'react'

const SpinnerLoader = () => {
    return (
        <div className='d-flex justify-content-center py-10'>
            <span className='indicator-progress' style={{ display: 'block' }}>

                <span className='spinner-border align-middle ms-2'></span>
            </span>
        </div>
    )
}

export default SpinnerLoader