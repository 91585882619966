import clsx from 'clsx'
import { useLayout } from '../../layout/core'
import { useAuth } from '../../app/modules/auth'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const { config } = useLayout()
  const { currentUser, logout } = useAuth()
  return (
    <div className='app-navbar flex-shrink-0'>


      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <button onClick={logout} className='btn btn-secondary px-5'>
              Sign Out
          </button>
        </div>
      </div>
    </div>
  )
}

export { Navbar }
