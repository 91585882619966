import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  return (
    <>
      <MenuItem title='Dashboard' to='/dashboard' />
      <MenuItem title='Category' to='/category' />
      <MenuItem title='Segments' to='/segments' />
    </>
  )
}
