
import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTIcon } from '../../../helpers/components/KTIcon';
import { useAppData } from '../../../components/appcontroller/AppData';
import { saveSegment } from '../../../components/appcontroller/segment';
export interface ISegment {
  parent_segment: string|null,
  rule: string,
  label: string,
  description: string|null
}
export const segmentInitValues: ISegment = {
  parent_segment: null,
  rule: '',
  label: '',
  description:null
};
const profileDetailsSchema = Yup.object().shape({
  parent_segment: Yup.string().nullable(),
  rule: Yup.string().required('Rule is required'),
  label: Yup.string().required('Label is required'),
  description: Yup.string().nullable(),
})
const AddSegmentModal: FC = () => {
  const {updateSegmentList} = useAppData()
  const [loading, setLoading] = useState(false)
  const formik = useFormik<ISegment>({
    initialValues: segmentInitValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values, { setStatus,resetForm }) => {
      
      setLoading(true)
      try {
        const data = await saveSegment(values)
        if (data.error) {
          setStatus({type:'failed',message:data.message})
        }else{
          resetForm({values:segmentInitValues})
          setStatus({type:'success',message:data.message})
          updateSegmentList()
        }
      } catch (error) {
        console.log(error);
        setStatus({type:'failed',message:"Not able to add segment"})
      }

      setLoading(false)
    },
  })
  
  return (
    <div className='modal fade' id='kt_modal_add_segment' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-5'>
              <h1 className='mb-3'>Add new Segment</h1>
            </div>

            <div className='separator d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'></span>
            </div>
            {formik.status && <>{formik.status?.type==='failed' ? (
              <div className='mb-lg-3 alert alert-danger'>
                <div className='alert-text fw-bold text-center'>{formik.status.message}</div>
              </div>
            ) : (
              <div className='mb-lg-3 alert alert-success'>
                <div className='alert-text fw-bold text-center'>
                  Successfully added segment
                </div>
              </div>
            )}</>}
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body py-2'>
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label required fw-bold fs-6'>Segment name</label>

                  <div className='col-lg-12'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Segment name'
                      {...formik.getFieldProps('label')}
                    />
                    {formik.touched.label && formik.errors.label && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.label}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label required fw-bold fs-6'>Segment rule</label>

                  <div className='col-lg-12'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Segment rule'
                      {...formik.getFieldProps('rule')}
                    />
                    {formik.touched.rule && formik.errors.rule && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.rule}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>Parent segment</label>

                  <div className='col-lg-12'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Parent segment'
                      {...formik.getFieldProps('parent_segment')}
                    />
                    {formik.touched.parent_segment && formik.errors.parent_segment && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.parent_segment}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>Description</label>

                  <div className='col-lg-12'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Description'
                      {...formik.getFieldProps('description')}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.description}</div>
                      </div>
                    )}
                  </div>
                </div>

              </div>
              <div className='row mb-2 mt-2'>
                <div className='col-lg-12'>
                  <button type='submit' className='btn btn-primary col-lg-12' disabled={loading}>
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  )
}

export { AddSegmentModal }
