import React, { FC } from 'react'

import PieChart from '../../GlobalComponents/PieChart';
type PropsPieChart = {
    state: any,
    className?: string,
    pieChartType?: string
}
const DailyReportPieChart: FC<PropsPieChart> = ({ className, state, pieChartType = "users" }) => {
    const sortedData = React.useMemo(() => {

        let sortableData = state.reportData.map((element: any) => {
            return { category: element["parentcategory"].toUpperCase(), count: element[pieChartType] }
        });

        sortableData.sort((a: any, b: any) => { return b.count - a.count });
        let pieData = sortableData.slice(0, 9)
        const others = sortableData.slice(9).reduce((acc: number, curr: any) => acc + curr.count, 0);
        return [...pieData, { category: "OTHERS", count: others }];
    }, [state, pieChartType]);
    const sortedData2 = React.useMemo(() => {
        let sortableData = state.reportData.map((element: any) => {
            return { category: element["parentcategory"].toUpperCase(), count: element[pieChartType] }
        });
        sortableData.sort((a: any, b: any) => { return b.count - a.count });
        let pieData = sortableData.slice(1, 10)
        const others = sortableData.slice(10).reduce((acc: number, curr: any) => acc + curr.count, 0);
        return [...pieData, { category: "OTHERS", count: others }];
    }, [state, pieChartType]);
    if (state.loading) return <div className={className}>
        <div className="position-relative d-flex justify-content-center align-items-center" style={{ width: "300px", height: "200px" }}>
            <img className="w-100 h-100 position-absolute" src="/media/chartskeleton/barChartSkeleton.png" alt="" />
            <div className="position-absolute d-flex flex-column align-items-center">
                <div className='fs-2 text-black fw-bold'>{"Loading data..."}</div>
                {/* {error ? <button className='btn btn-primary shadow-sm py-2' onClick={getAllSegmentData}>Reload</button> : <></>} */}
            </div>
        </div>
        <div className="position-relative d-flex justify-content-center align-items-center" style={{ width: "300px", height: "200px" }}>
            <img className="w-100 h-100 position-absolute" src="/media/chartskeleton/barChartSkeleton.png" alt="" />
            <div className="position-absolute d-flex flex-column align-items-center">
                <div className='fs-2 text-black fw-bold'>{"Loading data..."}</div>
                {/* {error ? <button className='btn btn-primary shadow-sm py-2' onClick={getAllSegmentData}>Reload</button> : <></>} */}
            </div>
        </div>

    </div>
    return (
        <div className={className}>

            <PieChart sortedData={sortedData} title={"ALL CATEGORIES"} className={"d-flex flex-column align-items-center"} chartType={pieChartType.toUpperCase()}/>
            <PieChart sortedData={sortedData2} title={`CATEGORIES EXCLUDING ${sortedData[0].category.toUpperCase()}`} className={"d-flex flex-column align-items-center"} chartType={pieChartType.toUpperCase()}/>
            {/* end::Chart */}
        </div>
    )
}

export default DailyReportPieChart