import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS } from '../../../assets/ts/_utils';
const NumberWithCommas = (number: string | number | number[]) => {
    const formattedNumber = new Intl.NumberFormat('en-IN').format(Number(number));
    return formattedNumber;
};
const PieChart = ({ className, sortedData, title, chartType }: any) => {

    const chartRef = useRef<HTMLDivElement | null>(null);
    var options = {
        series: sortedData.map((element: any) => {
            return element.count
        }),
        chart: {
            width: 500,
            type: 'donut',
        },
        labels: sortedData.map((element: any) => {
            return element.category
        }),
        legend: {
            fontWeight: 600
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            show: true
                        },
                        value: {
                            show: true,
                            formatter: (val: any) => NumberWithCommas(val) + " " + chartType,
                            fontWeight: 500,
                            fontSize: "17px"
                        }
                    }
                }
            }
        },
        tooltip: {
            enabled: false
        },
        responsive: [{
            breakpoint: 700,
            options: {
                chart: {
                    width: 300
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                name: {
                                    fontSize:"11px"
                                },
                                value: {
                                    fontSize: "12px",
                                }
                            }
                        }
                    }
                },
                legend: {
                    show: false,
                },
                
            }
        }]
    };
    const refreshChart = () => {
        if (!chartRef.current) {
            return;
        }

        const height = parseInt(getCSS(chartRef.current, "height"));

        const chart = new ApexCharts(
            chartRef.current, options
        );
        if (chart) {
            chart.render();
        }

        return chart;
    };
    useEffect(() => {
        const chart = refreshChart();
        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [chartRef, sortedData]);
    return (
        <div className={`${className}`}>
            <div
                ref={chartRef}
                id="kt_charts_widget_2_chart"
                className='mh-350px'
            ></div>
            <div className='fs-5 fs-md-3 fw-bold my-7'>{title}</div>
        </div>
    )
}

export default PieChart