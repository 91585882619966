import axios from "axios";

const getAllSegments = async () => {
    try {
        const response = await axios.get(`segments/getSegments`);
        // const data = await response.json();
        if (response.data && response.data?.length) {
            return response.data;  
        }
        else {
            return ({error:"error 404",message:"Data not found"})
        }
    } catch (error) {
        console.log(error);
        
        return({error:"error 500",message:"Internal server error"});
    }
}

const saveSegment = async (values: any) => {
    try {
        const res = await axios.post(`segments/addSegment`, values)
        const data = await res.data
        
        return data;
    } catch (error:any) {
        return ({ error: error.response.status, message: error.response.data.message });
    }
}

export {getAllSegments,saveSegment}