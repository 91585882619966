import DailyReportPieChart from './DailyReportPieChart'
import DailyReportTable from './DailyReportTable'
import React, { useReducer, useRef, useState } from 'react'
import { DailyReportreducer } from '../utils/getReducerOptions'
import axios, { Axios } from 'axios'
import Flatpickr from "react-flatpickr";
import { BsCaretDownFill } from "react-icons/bs";
const initialState = {
    loading: true,
    reportData: [],
    error: null,
    chart:false
}
const DailyReport = ({ className }: { className: string }) => {
    const [state, dispatch] = useReducer(DailyReportreducer, initialState)
    let todayDate = new Date().toISOString().slice(0, 10);
    const [reportDate, setReportDate] = React.useState(todayDate);
    const [pieChartType, setPieChartType] = useState("Users")

    const abortControllerRef = useRef<AbortController | null>(null);
    const fetchDailyReport = async () => {
        try {
            dispatch({ type: "FETCH_BEGIN" })
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            abortControllerRef.current = new AbortController();
            const response = await axios.get(`/categories/dailyReport`, {
                params: {
                    date: reportDate
                },
                signal: abortControllerRef.current.signal
            });
            const data = response.data

            if (response.data.err)
                dispatch({ type: "FETCH_FAIL", error: "Unable to daily report" })
            else {
                dispatch({ type: "FETCH_SUCCESS", payload: data })
            }
        } catch (error: any) {
            if (axios.isCancel(error) || error.name === 'AbortError') {
                console.log('Request aborted:', error.message);
            } else {
                dispatch({ type: "FETCH_FAIL", error: "Unable to fetch daily report" });
            }
        }
    }

    React.useEffect(() => {
        fetchDailyReport()
    }, [reportDate])
    return (

        <div className={`card ${className}`}>
            <div className='card-header px-5 px-md-10 border-0 pt-3 pt-md-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Daily Report</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Categories</span>
                </h3>

                <div className="d-flex">
                    <div className="dropdown-center mx-4">
                        <button className="btn d-flex justify-content-between align-items-center w-150px fs-5 fw-bold py-3 px-4 rounded-2" style={{ color: "white", backgroundColor: "rgb(163 163 163)" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {pieChartType}
                            <BsCaretDownFill />
                        </button>
                        <ul className='dropdown-menu'>
                            <li onClick={() => setPieChartType("Users")} className='dropdown-item cursor-pointer text-dark'>Users</li>
                            <li onClick={() => setPieChartType("Views")} className='dropdown-item cursor-pointer text-dark'>Views</li>
                            <li onClick={() => setPieChartType("Stories")} className='dropdown-item cursor-pointer text-dark'>Stories</li>
                        </ul>
                    </div>
                    <div className="">
                        <Flatpickr
                            value={reportDate}
                            onChange={([date]) => {
                                const dateTime = date.getTime() + 5.5 * 60 * 60 * 1000

                                setReportDate(new Date(dateTime).toISOString().slice(0, 10));
                            }}
                            options={{
                                maxDate: todayDate
                            }}
                            className="d-inline form-control bg-gray-200 placeholder-gray-800 text-dark cursor-pointer"
                            placeholder="Pick date"
                            id="pickDate"
                        />
                    </div>
                </div>
            </div>
            {(state.error || state.reportData.length <= 0) && !state.loading ? <div className='d-flex flex-column justify-content-center align-items-center h-300px'>
                <h3>{state.error ? "Some error occured while loading report" : "Daily report data not available"}</h3>
                <button className='btn btn-primary shadow-sm py-2' onClick={fetchDailyReport}>Reload</button>
            </div>:
            <div className='card-body px-5 px-md-10 py-3'>
                {state.chart && <DailyReportPieChart pieChartType={pieChartType.toLowerCase()} state={state} className='d-lg-flex justify-content-evenly  py-2 px-2' />}
                <DailyReportTable state={state} fetchDailyReport={fetchDailyReport} />
            </div>}
        </div>
    )
}

export default DailyReport