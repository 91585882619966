
import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAppData } from '../../../components/appcontroller/AppData';
import { saveCategory } from '../../../components/appcontroller';
import { KTIcon } from '../../../helpers/components/KTIcon';
export interface ICatgeory {
  parent_category: string|null,
  label: string,
  type: string,
  slug:string|null
}
export const categoryInitValues: ICatgeory = {
  parent_category: null,
  label: '',
  type: '',
  slug:null
};
const profileDetailsSchema = Yup.object().shape({
  parent_category: Yup.string().nullable(),
  label: Yup.string().required('Label is required'),
  type: Yup.string().required('Type is required'),
  slug:Yup.string().nullable()
})
const typeOptions = [
  { label: "PLATFORM", value: "PLATFORM" },
  { label: "AGE_GROUP", value: "AGE_GROUP" },
  { label: "CONTENT_CATEGORY", value: "CONTENT_CATEGORY" },
  { label: "LOCATION", value: "LOCATION" },
]
const AddCategoryModal: FC = () => {
  const {updateCategoryList} = useAppData()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');
  const formik = useFormik<ICatgeory>({
    initialValues: categoryInitValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values,{setStatus,resetForm}) => {
      setError('')
      setLoading(true)
      try {
        const data = await saveCategory(values);
        if (data.error) {
          setStatus({type:'failed',message:data.message})
          console.log(data.error);
        }else{
          resetForm({values:categoryInitValues})
          setStatus({type:'success',message:data.message})
          updateCategoryList()
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false)
    },
  })

  return (
    <div className='modal fade' id='kt_modal_add_category' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-5'>
              <h1 className='mb-3'>Add new Category</h1>
            </div>

            <div className='separator d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'></span>
            </div>
            {formik.status && <>{formik.status?.type==='failed' ? (
              <div className='mb-lg-3 alert alert-danger'>
                <div className='alert-text fw-bold text-center'>{formik.status.message}</div>
              </div>
            ) : (
              <div className='mb-lg-3 alert alert-success'>
                <div className='alert-text fw-bold text-center'>
                  Successfully added category
                </div>
              </div>
            )}</>}
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body py-2'>
                
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label required fw-bold fs-6'>Category label</label>

                  <div className='col-lg-12'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Category label'
                      {...formik.getFieldProps('label')}
                    />
                    {formik.touched.label && formik.errors.label && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.label}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>Parent category</label>

                  <div className='col-lg-12'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Parent category'
                      {...formik.getFieldProps('parent_category')}
                    />
                    {formik.touched.parent_category && formik.errors.parent_category && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.parent_category}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label required fw-bold fs-6'>Category type</label>

                  <div className='col-lg-12 fv-row'>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('type')}
                    >
                      <option value={''}>Select category type</option>
                      {typeOptions.map((option)=>{
                        return <option key={option.value} value={option.value}>{option.label}</option>
                      })}
                    </select>
                    {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.type}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-3'>
                  <label className='col-lg-12 col-form-label fw-bold fs-6'>Slug</label>

                  <div className='col-lg-12'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Slug'
                      {...formik.getFieldProps('slug')}
                    />
                    {formik.touched.slug && formik.errors.slug && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.slug}</div>
                      </div>
                    )}
                  </div>
                </div>

              </div>
              <div className='row mb-2 mt-2'>
                <div className='col-lg-12'>
                  <button type='submit' className='btn btn-primary col-lg-12' disabled={loading}>
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  )
}

export { AddCategoryModal }
